<template>
  <b-row class="broker-account">
    <account-setting />
  </b-row>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import AccountSetting from './broker/account-editing/AccountSetting.vue'

export default {
  components: {
    BRow,
    AccountSetting,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
