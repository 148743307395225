<template>
  <b-col>
    <form-wizard
      color="#F8AC18"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Enviar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="vertical-steps steps-transparent mb-3 pre_broker"
      @on-complete="validationForm">
      <!-- data -->
      <tab-content title="Detalhes da Conta" icon="feather icon-user">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon icon="UserIcon" size="18" />
                  <h4 class="mb-0 ml-75">Dados Pessoais</h4>
                </div>
              </b-col>
              <b-col sm="6">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-form-group label="CPF / CNPJ" label-for="account-document">
                    <b-form-input
                      v-model="registerData.numberCpfCnpj"
                      disabled />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="6">
                <b-form-group label="Email" label-for="account-document">
                  <b-form-input v-model="registerData.emailAddress" disabled />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label="Nome Completo / Razão Social"
                  label-for="account-document">
                  <b-form-input v-model="registerData.name" disabled />
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group
                  label-for="example-datanasc"
                  label="Data de Nascimento">
                  <validation-provider #default="{ errors }" name="Data de Nascimento" rules="required">
                    <b-form-input
                    id="register-birthDate"
                    v-model="registerData.birthDate"
                    v-mask="['##/##/####']"
                    name="register-birthDate"
                    :state="errors.length > 0 ? false : null"
                    @change="changeDate('register-birthDate')"
                    placeholder="dd/mm/aaaa" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group label="Celular" label-for="account-document">
                  <b-form-input v-model="registerData.phoneNumber" />
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group
                  label="Documento de Identificação"
                  label-for="account-document">
                  <b-form-input v-model="registerData.numberRg" disabled />
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group
                  label="Região de Atuação"
                  label-for="account-document">
                  <b-form-input v-model="registerData.cityOperation" />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="CRECI" label-for="creci">
                  <b-form-input v-model="registerData.creci" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </tab-content>

      <!-- anexos -->
      <tab-content title="Documentos" icon="feather icon-file-text">
        <b-row>
          <b-col cols="12">
            <b-col>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon icon="UserIcon" size="18" />
                  <h4 class="mb-0 ml-75">Documentos</h4>
                </div>
              </b-col>
              <ul class="detailing__grid">
                <div v-for="(attachment, key) in attachments" :key="key">
                  <li
                    v-if="attachment.fileSituation === 4 || attachment.fileSituation === 3"
                    class="detailing__document-card">
                    <div>
                      <b-form-file
                        :id="attachment.keyName"
                        :ref="attachment.keyName"
                        v-model="attachment.bytes"
                        :accept="typeFiles"
                        size="sm"
                        placeholder="Arquivo"
                        required
                        @change="getBase64($event, key)"
                        />
                      <h5 class="mt-2 detailing__ft-size-14 text-center">
                        {{ attachment.docName }}
                      </h5>
                      <input v-model="attachment.keyName" type="hidden">
                      <input v-model="attachment.id" type="hidden">
                    </div>
                  </li>
                </div>
              </ul>
            </b-col>
          </b-col>
        </b-row>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </tab-content>
    </form-wizard>
  </b-col>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { required } from '@validations'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions,no-unused-vars
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'
import dataTypeFiles from '../../../helper/dataTypeFiles'
import toast from '@/mixins/toast'
import formatter from '@/mixins/formatter'

export default {
  directives: {
    Ripple,
  },
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    Portuguese,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    Moment,
  },
  props: {
    pfFilesForm: {
      type: [String, Array],
    },
  },
  mixins:[toast, formatter],
  data() {
    return {
      typeFiles: dataTypeFiles.types,
      userId: '',
      required,
      registerData: {
        typePerson: 'F',
        name: '',
        surname: '',
        userName: '',
        emailAddress: '',
        phoneNumber: '',
        numberCpfCnpj: '',
        numberRg: '',
        birthDate: '',
        creci: '',
        cityOperation: '',
      },

      snowOption: {
        theme: 'snow',
        content: '',
        codeSnow: '',
      },
      selected: 'default',
      selectOptions: [
        { value: 'default', text: 'Em Avaliação' },
        { value: 'confirmado', text: 'Confirmado' },
        { value: 'rejeitado', text: 'Rejeitado' },
      ],
      renot: true,
      attachments: [],
      config: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      warning: 'Arquivo a ser anexado deve ter até 1MB',
    }
  },
  watch: {
    attachments() {
      this.$emit('input', this.value)
    },
  },
  async mounted() {
    this.getDataUser()
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    localStorage.setItem('quiz', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    async alterData() {
      const dataBroke = this.treatDataBrokerRegister()
      this.brokerRegisterData(dataBroke)
    },
    changeDate(id){
      formatter.validateDate(id, this)
    },
    validationForm() {
      this.alterData()
    },

    async brokerRegisterData(data) {
      try {
        await this.$http
          .post('/api/services/app/UserBrokerProfiles/CreateOrEdit', data, {
            withCredentials: false,
            timeout: 30000,
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          })
          .then(() => {
            const fileBroke = this.treatFileBrokerRegister()
            this.brokerRegisterFiles(fileBroke)

            this.msgSuccess()
            setTimeout(() => {
              this.$router.push({ name: 'broker-register-analysis' })
            }, 2700)
          })
          .catch(error => {
            this.msgError()
            this.errors = error.message
          })
      } catch (error) {
        this.msgError()
      }
    },
    async brokerRegisterFiles(data) {
      try {
        await this.$http
          .put('/api/services/app/UserBrokerDatas/Update', data, {
            withCredentials: false,
            timeout: 30000,
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          })
          .then(response => response.status)
          .catch(error => {
            this.errors = error.message
          })
      } catch (error) {
        this.errors = error.message
      }
    },

    treatDataBrokerRegister() {
      const data = {
        cityOperation: this.registerData.cityOperation,
        creci: this.registerData.creci,
        situation: 1,
        termsAccepted: true,
        user: {
          name: this.registerData.name,
          phoneNumber: this.registerData.phoneNumber,
          // numberCpfCnpj: this.registerData.numberCpfCnpj.replace(/[^\d]+/g, ''),
          numberRg: this.registerData.numberRg,
          birthDate: this.registerData.birthDate,
          cityOperation: this.registerData.cityOperation,
          emailAddress: this.registerData.emailAddress,
          typePerson: this.registerData.typePerson,
          userName: this.registerData.userName,
          surname: this.registerData.surname,
        },
        id: this.userId,
      }
      return data
    },
    treatFileBrokerRegister() {
      const filtered = this.attachments.filter(el => el.bytes !== null)
      const obj = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const value of filtered) {
        obj[value.keyName] = {
          id: value.id,
          bytes: value.bytes.split(',')[1],
          description: null,
          dataType: value.bytes.split(',')[0],
        }
      }

      return obj
    },

    async getDataUser() {
      // pega dados do broker
      const dataUser = await this.$http
        .get('/api/services/app/UserBrokerProfiles/GetBrokerSession', {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })

      const fileUserSituation = await this.$http
        .get('/api/services/app/UserBrokerDatas/GetSituationFiles', {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      const fileUser = await this.$http
        .get(
          `/api/services/app/UserBrokerProfiles/GetUserBrokerProfileForValidation?Id=${dataUser.data.result.id}`,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      this.setDataUser(dataUser, fileUserSituation, fileUser)
    },
    setDataUser(data, fileSituation, fileUser) {
      // data user
      this.userId = data.data.result.id
      this.registerData.cityOperation = data.data.result.cityOperation
      this.registerData.creci = data.data.result.creci
      this.registerData.numberCpfCnpj = this.cpfCnpj(
        data.data.result.user.numberCpfCnpj,
      )
      this.registerData.numberRg = data.data.result.user.numberRg
      this.registerData.phoneNumber = data.data.result.user.phoneNumber
      this.registerData.emailAddress = data.data.result.user.emailAddress
      this.registerData.birthDate = this.frontEndDateFormat(
        data.data.result.user.birthDate,
      )
      this.registerData.name = data.data.result.user.name
      this.registerData.userName = data.data.result.user.userName
      this.registerData.surname = data.data.result.user.surname
      this.registerData.typePerson = 'F'
      this.registerData.typePersonName = data.data.result.user.typePerson === 'F' ? 'Corretor' : 'Imobiliária'

      const attachmentsData = [
        {
          id: fileUser.data.result.creciFile ? fileUser.data.result.creciFile.id : null,
          bytes: null,
          docName: 'CRECI',
          selectedName: 'selectedCreci',
          keyName: 'CreciFile',
          fileSituation: fileSituation.data.result.creciFileSituation,
        },
        {
          id: fileUser.data.result.facePhotoCreciFile ? fileUser.data.result.facePhotoCreciFile.id : null,
          bytes: null,
          docName: 'Self CRECI',
          selectedName: 'selectedFaceCreci',
          keyName: 'FacePhotoCreciFile',
          fileSituation: fileSituation.data.result.facePhotoCreciFileSituation,
        },
        {
          id: fileUser.data.result.documentFile ? fileUser.data.result.documentFile.id : null,
          bytes: null,
          docName: 'Documento',
          selectedName: 'selectedDocument',
          keyName: 'DocumentFile',
          fileSituation: fileSituation.data.result.documentFileSituation,
        },
        {
          id: fileUser.data.result.facePhotoDocumentFile ? fileUser.data.result.facePhotoDocumentFile.id : null,
          bytes: null,
          docName: 'Self Documento',
          selectedName: 'selectedFaceDocument',
          keyName: 'FacePhotoDocumentFile',
          fileSituation: fileSituation.data.result.facePhotoDocumentFileSituation,
        },
      ]
      this.attachments = attachmentsData
    },

    async getBase64(event, key) {
      if (this.checkSizeFile(event) !== 1) {
        this.clearFiles(event)
        this.msgWarning()
        return
      }
      const reader = new FileReader()
      // eslint-disable-next-line no-return-assign
      reader.onload = e => (this.attachments[key].bytes = e.target.result)
      reader.readAsDataURL(event.target.files[0])
    },
    frontEndDateFormat(v) {
      return Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    cpfCnpj(v) {
      // eslint-disable-next-line no-param-reassign
      v = v.replace(/\D/g, '')
      if (v.length <= 14) {
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      } else {
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/^(\d{2})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{4})(\d)/, '$1-$2')
      }
      return v
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id][0].reset()
    },
    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import './../../sass/_variables.scss';
  body {
    background-color: #f8f8f8 !important;
  }
.custom-file-input:focus ~ .custom-file-label {
  border-color: var(--color)  !important;
}
.wizard-footer-right > span > .wizard-btn {
    background-color: var(--color) !important;
    border-color: var(--color)  !important;
    color: white;
    &:hover {
      box-shadow: 0 8px 25px -8px var(--color) !important;
    }
}
.mt-personnalite-2 {
  margin-top: 2px;
}
.wizard-card-footer {
  margin-left: 228px !important;
}
li.active a {
  background-color: var(--color-bg) !important;
}
.wizard-icon-container {
  background-color: rgb(248 172 24 / 0%) !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgb(105 108 255 / 0%) !important;
}

  .vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle.checked {
  background-color: rgb(248 172 24 / 0%) !important;
}
.vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .wizard-icon-circle
  .wizard-icon-container
  .wizard-icon {
  color: var(--color) !important;
  font-size: 1.3rem !important;
}
.stepTitle {
  padding-right: 12px !important;
}
.active {
  border-color: #fff6e800 !important;
  box-shadow: 0 4px 18px -4px #ffffff00 !important;
}
.wizard-icon {
  color: #5e5873 !important;
}
div.wizard-navigation li.active a span {
  color: var(--color) !important;
  font-weight: 500 !important;
}
div.wizard-navigation li a span {
  color: #5e5873 !important;
  font-weight: 500 !important;
}
div.wizard-navigation .wizard-tab-content {
  margin-left: 35px;
}
.wizard-icon-circle {
  margin-right: 0px !important;
}
.vertical-steps.vue-form-wizard .wizard-navigation .wizard-nav {
  padding-top: 0px !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li {
  padding-bottom: 0.2rem !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
  border: none;
  background-color: #ededed00 !important;
  border-radius: 6px;
}
.vue-form-wizard .wizard-card-footer {
  margin-left: 254px;
}
ul {
  padding-inline-start: 0px !important;
}

.detailing {
  box-sizing: border-box;
  padding: 10px 20px;

  & span,
  & td {
    color: $color-black;
  }

  &__ft-size-10 {
    font-size: 10px;
  }
  &__ft-size-14 {
    font-size: 14px;
  }

  &__title {
    font-family: $source-sans-pro;
    color: $color-black;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      flex-direction: row;
    }
  }

  &__document-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

    border-radius: 9px;
    box-sizing: border-box;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  &__feedback-description {
    font-size: 16px;
    line-height: 19px;
    color: #a6adba;
    width: 80%;
    @media (min-width: 900px) {
      max-width: 600px;
    }
  }

  .ql-container {
    min-height: 200px;
  }
}
</style>
